<!--人才档案-->
<template>
  <div class="file w-1400">
    <el-row :gutter="30">
      <el-col :span="18">
        <div class="main-l">
          <div class="header">
            <div class="fl">
              <h3 class="fl">分类</h3>
              <ul class="fl clearfix">
                <li :class="{isact:params.filter.personnelType==''}"        class="fl header-list"  @click="params.filter.personnelType = '';        queryPersonnelfileList()">不限</li>
                <li :class="{isact:params.filter.personnelType=='创业人才'}" class="fl header-list"  @click="params.filter.personnelType = '创业人才'; queryPersonnelfileList()">创业人才</li>
                <li :class="{isact:params.filter.personnelType=='返乡人才'}" class="fl header-list"  @click="params.filter.personnelType = '返乡人才'; queryPersonnelfileList()">返乡人才</li>
                <li :class="{isact:params.filter.personnelType=='本地人才'}" class="fl header-list"  @click="params.filter.personnelType = '本地人才'; queryPersonnelfileList()">本地人才</li>
                <li :class="{isact:params.filter.personnelType=='宁人回乡'}" class="fl header-list"  @click="params.filter.personnelType = '宁人回乡'; queryPersonnelfileList()">宁人回乡联络站</li>
              </ul>
            </div>
            <div class="fr more">
              <!-- <router-link to="/person">
                更多<i class="el-icon-arrow-right"></i
              ></router-link> -->
            </div>
          </div>
          <div
            class="card clearfix"
            v-for="item in pageData.list"
            :key="item.id + 'card'"
          >
            <router-link :to="`persondetail?id=${item.id}`">
              <div class="fl card-pic">
                <el-image
                  :src="item.photoUrls"
                  style="width: 100%; height: 100%"
                  fit="cover"
                ></el-image>
              </div>
              <div class="fl card-info">
                <div class="card-name row">
                  <div>{{ item.name }}</div>
                  <div>{{item.type}}</div>
                </div>
                <div class="card-intro">
                  {{ item.honorInfo }}
                </div>
                <div class="card-time">{{ getDate(new Date(item.createdTime)) }}</div> 
                <!-- 浏览量 {{ item.viewNumber }} -->
              </div>
            </router-link>
          </div>
        </div>
         <el-pagination class="pagination" background layout="prev, pager, next,jumper" :total="pageData.total" @current-change="handleCurrentChange"> </el-pagination>
      </el-col>
      <el-col :span="6">
        <div class="main-r">
          <div class="header">
            <div class="fl">
              <h3 class="fl">热门推荐</h3>
            </div>
            <!-- <div class="fr more">更多<i class="el-icon-arrow-right"></i></div> -->
          </div>
          <div
            class="card2 clearfix"
            v-for="item in pageDatarecommend"
            :key="item.id + 'card'"
          >
          <router-link :to="`persondetail?id=${item.id}`">
            <div class="fl card2-pic">
              <el-image
                :src="item.photoUrls"
                style="width: 100%; height: 100%"
                fit="cover"
              ></el-image>
            </div>
            <div class="fl card2-info">
              <div class="card2-name">{{ item.name }}</div>
              <div class="card2-intro">
                {{ item.academicInfo }}
              </div>
          
              <!-- <div class="card-time">2021-10-09 浏览量 248</div> -->
            </div>
          </router-link>
          </div>
        </div>
      </el-col>
    </el-row>
  </div>
</template>

<script>
import { GetPersonnelfilePage, GetPersonnelfile } from "@/api/xiangningqing";
import { getDate } from '@/utils/date.js'
export default {
  data() {
    return {
      pageData: {},
      pageDatarecommend: {},
      params: {
        currentPage: 1,
        pageSize: 10,
        filter: {
          personnelType: "",
        },
      },
      getDate:getDate
    };
  },
  mounted() {
    this.queryPersonnelfileList();
    this.getPersonnelfilerecommend();
  },
  methods: {
    getPersonnelfileList() {
      GetPersonnelfilePage(this.params).then((res) => {
        this.pageData = res.data.data;
      });
    },
    getPersonnelfilerecommend() {
      const reparams = { currentPage: 1, pageSize: 10, filter: {} };
      GetPersonnelfilePage(reparams).then((res) => {
        this.pageDatarecommend = res.data.data.list;
      });
    },
    //条件查询
    queryPersonnelfileList() {
      this.params.currentPage = 1; //注意！
      this.getPersonnelfileList();
    },
    //分页查询
    handleCurrentChange(page) {
      this.params.currentPage = page;
      this.getPersonnelfileList();
    },
  },
};
</script>

<style scoped>
.pagination{
    text-align: center;
}
.card-time {
  font-size: 16px;
  line-height: 65px;
  padding-left: 12px;
}
.card-intro {
  text-indent: 28px;
  font-size: 16px;
  /* font-weight: 600; */
  line-height: 20px;
  min-height: 60px;
  color: #666;
  display: -webkit-box;
  overflow: hidden;
  text-overflow: ellipsis;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
}
.card2-intro {
  text-indent: 20px;
  font-size: 14px;
  line-height: 20px;
  min-height: 60px;
  color: #666;
  font-size: "微软雅黑";
  display: -webkit-box;
  overflow: hidden;
  text-overflow: ellipsis;
  -webkit-line-clamp: 4;
  -webkit-box-orient: vertical;
}
.card-name {
  font-size: 22px;
  line-height: 50px;
  font-weight: 700;
}
.card-name>div:last-child{
  font-size: 14px;
  color: #3b8edb;
  margin-left: 20px;
  transform: translateY(4px);
}
.card2-name {
  font-size: 18px;
  line-height: 30px;
  font-weight: 600;
}
.card2-info {
  height: 110px;
  overflow: hidden;
}
.card-info {
  width: calc(100% - 165px);
  margin-left: 18px;
}
.card2-info {
  width: calc(100% - 115px);
  margin-left: 18px;
}
.card-pic {
  width: 145px;
  height: 175px;
  overflow: hidden;
}
.card2-pic {
  width: 95px;
  height: 110px;
  overflow: hidden;
}
.card,
.card2 {
  padding: 20px;
  border-bottom: 1px solid #ccc;
  border-radius: 3px;
}
.more {
  margin-top: 3px;
  cursor: pointer;
}
.main-l,
.main-r {
  border: 1px solid #ccc;
  border-radius: 3px;
}
.header-list.isact {
  background: #0069c9;
  color: #fff;
}
.header-list {
  padding: 3px 12px;
  border: 1px solid #ccc;
  border-radius: 3px;
  background: #fff;
  margin: 0 20px;
  box-sizing: border-box;
  cursor:pointer;
}
.header h3 {
  padding: 3px 12px;
}
.header {
  height: 50px;
  background: #dfdfdf;
  padding-top: 12px;
  font-size: 20px;
  border-bottom: 1px solid #ccc;
}
</style>